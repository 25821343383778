@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@pimy-b2cweb/frontend-lib/libSrc/styles/fonts.scss';
@import '@pimy-b2cweb/frontend-lib/libSrc/styles/mixins.scss';
@import '@pimy-b2cweb/frontend-lib/libSrc/styles/overrides.scss';
@import '@pimy-b2cweb/frontend-lib/libSrc/styles/main.scss';

.grecaptcha-badge {
  @apply invisible;
}

body {
  overflow-x: clip !important;
}

button.MuiButton-root.go-epf-button {
  @apply bg-black bg-none shadow-pi-gray-4 text-white #{!important};
}